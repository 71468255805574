<template>
    <CRow>
        <CCol col="12" xl="16">
            <CCard>
                <CCardHeader>
                    <CRow>
                        <CCol sm="4">
                            Users
                            <CInput v-c-tooltip.hover.click="`Query might be ${queries} `"
                                    @input="userLookup()" v-model="search_criteria" placeholder="query> value">
                                <template #prepend>
                                    <CButton @click="userLookup()" size="sm" color="primary">
                                        <CIcon name="cil-magnifying-glass"/>
                                        Search
                                    </CButton>
                                </template>
                            </CInput>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCol sm="10" class="mb-3 mb-xl-0">

                    <CRow sm="10">
                        <CCol sm="2">
                            <CSelect v-if="states"
                                     label="State"
                                     :options="states"
                                     :value.sync="state_id"
                                     @update:value="getCities()"
                            />

                        </CCol>

                        <CCol sm="2">
                            <CSelect v-if="cities"
                                     label="Cities"
                                     :options="cities"
                                     :value.sync="cit_id"
                                     @update:value="getProperties()"
                            />

                        </CCol>

                        <CCol sm="4">
                            <CSelect v-if="properties"
                                     label="Properties"
                                     :options="properties"
                                     :value.sync="pro_id"
                                     @update:value="getZones()"
                            />

                        </CCol>

                        <CCol sm="4">
                            <CSelect v-if="zones"
                                     label="Zones"
                                     :options="zones"
                                     :value.sync="zone_id"
                                     @update:value="getZones()"
                            />

                        </CCol>
                    </CRow>
                </CCol>

                <CModal
                        title="Choose which fields in table should be presented"
                        :show.sync="fieldChooseModal"
                        @update:show="closeModal"
                        closeOnBackdrop
                        size="xl"
                >
                    <template v-for="(name, key) in checkboxNames">
                        <CRow form class="form-group" :key="name">
                            <CCol tag="label" sm="3" class="col-form-label">
                                {{name}}
                            </CCol>

                            <CCol sm="2" :class="key % 2 === 1 ? 'form-inline' : ''">
                                <CInputCheckbox
                                        id="usTableFields"
                                        v-for="(option, optionIndex) in options"
                                        :key="key + option"
                                        :label="capitalizeFirstLetter(option)"
                                        :value="option"
                                        :custom="key > 1"
                                        :name="`Option 1${key}`"
                                        :inline="key % 2 === 1"
                                        :checked="chb_status[optionIndex]"
                                />
                            </CCol>
                            <br>

                        </CRow>
                    </template>
                </CModal>
                <CCardBody>
                    <CDataTable
                            hover
                            striped
                            sorter
                            columnFilter
                            itemsPerPageSelect
                            responsive
                            :items="items"
                            :fields="fields"
                            :items-per-page="50"
                            clickable-rows
                            :active-page="activePage"
                            @row-clicked="rowClicked"
                            :pagination="{ doubleArrows: true, align: 'center'}"
                            @page-change="pageChange"


                    >
                        <template #status="data">
                            <td>
                                <CBadge :color="getBadge(data.item.usr_status)">
                                    {{data.item.usr_status}}
                                </CBadge>
                            </td>
                        </template>
                    </CDataTable>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'Users',
        data() {
            return {
                baseURL: 'https://aerdesk-dot-ruckus-vsz-257719.uc.r.appspot.com',
                items: [],
                fieldChooseModal: false,
                checkboxNames: ['Choose what should be available in table'],
                options: [],
                chb_status: [],
                option: null,
                search_criteria: null,

                states: null,
                state_id: null,

                cities: null,
                cit_id: null,

                properties: null,
                pro_id: null,

                zones: null,
                zone_id: null,
                queries: ['usr_id', 'usr_firstname', 'usr_lastname', 'usr_email',
                    'usr_phonenumber',
                    'wlan_ssid', 'uni_name', 'rap_mac', 'rvlan_vlanid'],

                fields: [
                    {key: 'usr_id', label: this.capitalizeFirstLetter('usr_id')},
                    {key: 'usr_email', label: this.capitalizeFirstLetter('usr_email'), _classes: 'font-weight-bold'},
                    {key: 'usr_firstname', label: this.capitalizeFirstLetter('usr_firstname')},
                    {key: 'usr_lastname', label: this.capitalizeFirstLetter('usr_lastname')},
                    {key: 'usr_phonenumber', label: this.capitalizeFirstLetter('usr_phonenumber')},
                    {key: 'wlan_ssid', label: this.capitalizeFirstLetter('wlan_ssid')},
                    {key: 'zone_name', label: this.capitalizeFirstLetter('zone_name')},
                    {key: 'uni_name', label: this.capitalizeFirstLetter('uni_name')},
                    {key: 'rvlan_vlanid', label: this.capitalizeFirstLetter('rvlan_vlanid')},
                    {key: 'usr_status', label: this.capitalizeFirstLetter('usr_status')}
                ],
                activePage: 1
            }
        },
        watch: {
            $route: {
                immediate: true,
                handler(route) {
                    if (route.query && route.query.page) {
                        this.activePage = Number(route.query.page)
                    }
                }
            }
        },
        methods: {
            userLookup() {

                if (!this.search_criteria.includes('>')) {
                    return
                }
                var query = this.search_criteria.split('>')[0];
                var value = "";

                if (this.queries.includes(query)) {
                    value = this.search_criteria.split('> ')[1];
                }
                if (!value) {
                    return
                }

                axios.get(this.$baseURL + '/users/db/deep_search?param=' + query + "&value=" + value)
                    .then((res) => {
                        this.items = res.data;
                    }).catch(
                    (error) => {
                        console.log(error);
                    }
                )
            },
            getStates() {

                axios.get(this.$baseURL + '/treeview/states')
                    .then((res) => {
                        this.states = [];
                        this.states.push({label: "Select State", value: null})
                        res.data.forEach(e => {
                            this.states.push({label: e.state_name, value: e.state_id});
                        })
                    })

            },
            getCities() {
                if (!this.state_id) {
                    return
                }

                localStorage.setItem('state_id', this.state_id);

                axios.get(this.$baseURL + '/treeview/city?state_id=' + this.state_id)
                    .then((res) => {
                        this.cities = [];
                        this.cities.push({label: "Select City", value: null})
                        res.data.forEach(e => {
                            this.cities.push({label: e.cit_name, value: e.cit_id});
                        })
                    })

            },

            getProperties() {
                if (!this.cit_id) {
                    return
                }
                localStorage.setItem('cit_id', this.cit_id);
                axios.get(this.$baseURL + '/treeview/properties?cit_id=' + this.cit_id)
                    .then((res) => {
                        this.properties = [];
                        this.properties.push({label: "Select Property", value: null})
                        res.data.forEach(e => {
                            this.properties.push({label: e.pro_shortname, value: e.pro_id});
                        })
                    })

                this.getUsers('cit_id', this.cit_id);
            },

            getZones() {
                if (!this.pro_id) {
                    return
                }
                localStorage.setItem('pro_id', this.pro_id);
                axios.get(this.$baseURL + '/treeview/zones?pro_id=' + this.pro_id)
                    .then((res) => {
                        this.zones = [];
                        this.zones.push({label: "Select Zone", value: null})
                        res.data.forEach(e => {
                            this.zones.push({label: e.zone_name, value: e.zone_id});
                        })
                    })
                this.getUsers('pro_id', this.pro_id);

            },
            getBadge(status) {
                switch (status) {
                    case 1:
                        return 'success'
                    default:
                        'danger'
                }
            },
            rowClicked(item) {
                this.$router.push({path: `users/${item.usr_id}`})
            },
            closeModal(status, evt, accept) {
                if (accept) {
                    this.fields = [];
                    var checkboxes = document.querySelectorAll('input[id=usTableFields]:checked')

                    if (checkboxes.length === 0) {
                        this.fieldChooseModal = true;
                        this.fields = [
                            {key: 'usr_id', label: this.capitalizeFirstLetter('usr_id')},
                            {
                                key: 'usr_email',
                                label: this.capitalizeFirstLetter('usr_email'),
                                _classes: 'font-weight-bold'
                            },
                            {key: 'usr_firstname', label: this.capitalizeFirstLetter('usr_firstname')},
                            {key: 'usr_lastname', label: this.capitalizeFirstLetter('usr_lastname')},
                            {key: 'usr_phonenumber', label: this.capitalizeFirstLetter('usr_phonenumber')},
                            {key: 'wlan_ssid', label: this.capitalizeFirstLetter('wlan_ssid')},
                            {key: 'zone_name', label: this.capitalizeFirstLetter('zone_name')},
                            {key: 'uni_name', label: this.capitalizeFirstLetter('uni_name')},
                            {key: 'rvlan_vlanid', label: this.capitalizeFirstLetter('rvlan_vlanid')},
                            {key: 'usr_status', label: this.capitalizeFirstLetter('usr_status')}
                        ]


                        this.$toast.error("Please select at least one field!")
                    }
                    for (var i = 0; i < checkboxes.length; i++) {
                        this.fields.push({
                            'key': checkboxes[i].value,
                            'label': this.capitalizeFirstLetter(checkboxes[i].value)
                        })
                    }
                }
            },
            capitalizeFirstLetter(st) {
                var str_split = st.split('_')
                var new_array = []

                for (var ch = 0; ch < str_split.length; ch++) {
                    var newstr = str_split[ch].charAt(0).toUpperCase() + str_split[ch].slice(1);
                    new_array.push(newstr)
                }

                return new_array.join(' ')
            },
            activeSwitch() {
                this.active_sw = !this.active_sw;

                if (this.active_sw) {
                    var new_fields = [];

                    this.items.forEach(item => {
                        if (item.usr_status) {
                            new_fields.push(item);
                        }
                    })

                    this.items = new_fields;
                } else {
                    this.items = this.items_raw;
                }
                console.log("checked")
            },

            pageChange(val) {
                this.$router.push({query: {page: val}})
            },
            getUsers(param, val) {
                axios.get(this.$baseURL + '/users/all?param=' + param + '&val=' + parseInt(val), {})
                    .then((res) => {
                        this.items_raw = res.data;
                        this.items = res.data;
                        this.$toast.success(res.data.length + " APs loaded from DB.");
                        this.options = Object.keys(res.data[0]);

                        var hidden_list = ['rctl_api', 'rctl_username', 'rctl_password']
                        for (var i = 0; i < hidden_list.length; i++) {
                            this.options.filter(e => e !== hidden_list[i]);
                        }

                        var fields = [];
                        this.chb_status = [];
                        this.fields.forEach(el => {
                            fields.push(el.key)
                        })


                        console.log(fields);
                        this.options.forEach(option => {
                            this.chb_status.push(fields.includes(option));
                        })

                    }).catch((error) => {
                    this.$toast.error(error);
                })
            },
            getActiveUsers() {
                axios.get(this.$baseURL + '/users/active?low_range=' + 0 + '&up_range=' + 99999, {})
                    .then((res) => {
                        this.items = res.data;
                        this.$toast.success(res.data.length + " Users loaded from DB.");
                        console.log(res.data);

                    }).catch((error) => {
                    this.$toast.error(error);
                    console.log(error);
                })
            }
        },
        mounted() {
            this.validateSession();
            this.getStates();
        }
    }
</script>
